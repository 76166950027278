
import { Factcheck, ShortLink } from "@/types";
import { Component, Vue } from "vue-property-decorator";

import { GetFactCheck, UpdateFactcheck } from "@/services/FactCheckService";
import { ListShortLinksForFactcheck } from '@/services/ShortServices'
@Component({
  components: {
    EditSingleArticleSection: () => import('@/components/EditSingleArticleSection.vue'),
    SingleArticleStatsSection: () => import('@/components/SingleArticleStatsSection.vue'),
    CreateNewShortLinkDialog: () => import('@/components/dialogs/CreateNewShortLinkDialog.vue')
  }
})
export default class SingleArticleOverview extends Vue {
  tab = 0;

  dialogs = {
    datePicker: false,
    createArticle: false,
    newCategory: false,
    newShortLink: false
  };

  article = null as Factcheck | null;
  shortLinks = [] as Array<{ shortLink: ShortLink, interactions: any[] }>;


  async mounted() {
    await this.fetchFactcheck();
    await this.fetchShortLinks()
  }

  async togglePublished() {
    if (this.article) {
      await UpdateFactcheck({
        id: this.article.id,
        isPublished: this.article.isPublished
      })
    }
  }

  async fetchFactcheck() {
    this.article = await GetFactCheck(this.$route.params.id);
    if (this.article === null) {
      this.$router.push("/error?code=404");
    }
  }

  async fetchShortLinks() {
    this.shortLinks = await ListShortLinksForFactcheck(this.article!.id as string)
  }
}
