import { ScrapedArticle, ScrapeTask, ShortLink } from "@/types";

import api from '@/plugins/axios';



export const CreateShortLink = async (name: string, article: string, target: string, attributes: Record<string, string>): Promise<ShortLink> => {
    const { data } = await api.post('/s/', { name, factcheck: article, target, attributes });
    return data;
}

export const ListShortLinksForFactcheck = async (factcheck: string): Promise<Array<{ shortLink: ShortLink, interactions: any[] }>> => {
    const { data } = await api.get('/s/find', {
        params: {
            factcheck
        }
    });
    return data;
}
